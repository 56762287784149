
.main {
    width: 100%;
    height: 1000px;
    // background-color: pink;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .row {
        width: 100%;
        height: 240px;

        //    background-color: rgb(39, 120, 191);
        .row-card {
            width: 100%;
            height: 100%;
        }
    }

    .row1 {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .row1-content {
            width: 32%;
            height: 100%;

            .content {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text {
                    width: 200px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .title {

                        height: 44px;

                        font-weight: 400;
                        font-size: 24px;
                        color: #5F636C;
                        line-height: 28px;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                    }

                    .number {

                        height: 56px;

                        font-weight: 600;
                        font-size: 36px;
                        color: #2A2E34;
                        line-height: 50px;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                    }

                }

                .image {
                    width: 180px;
                    height: 180px;
                    display: block;

                }
            }

        }

    }
}

.header {
    width: 144px;
    height: 34px;

    font-weight: 600;
    font-size: 20px;
    color: #333333;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

::v-deep .el-card__body {
    height: 160px;
}
.heade-content{
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 60px;
    .item-content{
        width: 200px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
       
        .image{
            display: block;
            margin-right: 10px;
            width: 56px;
            height: 56px;
        }
        .text{
            width: 200px;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            line-height: 33px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
        
    }
}
.title-header{
    display: flex;
    height: 20px;
    align-items: center;
    .time{
        background-color: rgb(50,165,252);
        color: #fff;
        border-radius: 4px;
        height: 30px;
        display: block;
        line-height: 30px;
        padding: 0 20px;
    }
    
}


